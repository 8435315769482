import React from "react";
import Layout from "../../components/Common/Layout";
import Seo from "../../components/Common/Seo";
import Analyze from "../../components/Audit/Analyze";
import Deploy from "../../components/Audit/Deploy";
import Faq from "../../components/Audit/Faq";
import Hero from "../../components/Audit/Hero";
import Results from "../../components/Audit/Results";
import StorageAudit from "../../components/Audit/StorageAudit";
import AuditComponent from "../../components/Audit";

const Audit = () => {
  return (
    <Layout btnLink="/book-demo/audit">
      <Seo
        title="FREE Lucidity Storage Audit | Gain complete storage visibility"
        description={`Analyze how you spend, identify where you waste, and capture the risks of downtime, all at a click of a button with Lucidity Storage Audit`}
      />
      <AuditComponent />
    </Layout>
  );
};

export default Audit;
