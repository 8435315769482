import React, { useContext } from "react";
import { Accordion, AccordionContext, Col, Container, Row } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import plus from "../../assets/images/audit/plus.svg";
import * as classes from "../../styles/audit/audit.module.scss";
import "../../styles/audit/style.scss";

function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <div
      className={`toggle-header ${isCurrentEventKey ? `header-active` : ""}`}
      type="button"
      onClick={decoratedOnClick}
    >
      <img src={plus} alt="plus" />
      {children}
    </div>
  );
}

const Faq = () => {
  return (
    <section>
      <Container className={classes.audit__faq}>
        <Row className="justify-content-center">
          <Col lg={7} xs={12} className="text-center p-0">
            <div className={classes.heading}>
              <h2>
                Frequently Asked <span>Questions</span>
              </h2>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={12} lg={10}>
            <Accordion className="faq_accordian">
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="0">
                    How much time does it take to run the Audit?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    The time taken to run the audit tool depends on how many
                    VM's are there in a subscription. Typically for a 100 VM
                    subscription it takes 1h to complete.{" "}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="1">
                    Will it touch any of the customer data
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    No. Lucidity Audit uses Azure internal service to collect
                    storage metadata like - Storage utilization %, Disk size
                    etc. It never has any access to any customer PII or
                    sensitive data.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="2">
                    Will there be any impact to the environment ?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    No. Lucidity Disk Audit ensures no impact on the customer
                    cloud environment and resources.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="3">
                    Is there any log where I can check the status of the Audit
                    script?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    Yes. The Lucidity Audit tool saves environment discovery
                    reports and logs in a Linux directory or Windows folder from
                    the location where the script was invoked.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="4">What happens next?</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    Congratulations. You have taken the first step towards a
                    true NoOps cloud storage experience. Once the levers of your
                    storage wastage are confirmed, you will no longer be
                    surprised when we say we can reduce up to 70% of your
                    storage costs. And to do that, let us introduce the Lucidity
                    Autoscaler. Lucidity Autoscaler is an industry-first,
                    state-of-the-art and autonomous multi-cloud block storage
                    layer, making your block storage economical, reliable and
                    effortless. It is the industry's first cloud-native
                    auto-scaler that can shrink and expand your block storage
                    with zero downtime and no loss in performance. A true NoOps
                    cloud storage experience with no hassle of capacity planning
                    or manual provisioning. <a href='/product'>Know more {`>>`}</a>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faq;
