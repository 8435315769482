import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie-segments";
import * as classes from "../../styles/audit/audit.module.scss";
// lotie file
import * as bhagwan from "../../assets/images/audit/bhagwan.json";
import * as cloud from "../../assets/images/audit/cloud.json";
import * as secure from "../../assets/images/audit/secure.json";
import Tick from "../../assets/images/audit/tick.svg";

import DemoLargeButton from "../Common/DemoLargeButton";

const lottieSetting = (data) => {
  return {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
};

const Deploy = () => {
  return (
    <>
    <section>
      <Container className={classes.audit__deploy}>
        <Row className="justify-content-center">
          <Col lg={7} xs={12} className="text-center p-0">
            <div className={classes.heading}>
              <h2>
                How can I <span>deploy it?</span>
              </h2>
            </div>
            <div className={classes.body}>
              <p>
                The deployment of the Lucidity Storage Audit has been designed
                to be seamless with minimal DevOps requirements. And we ensure
                Lucidity never has any access to any of your data.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={12}>
            <div className={classes.deploy_box}>
              <div>
                <div className={classes.circle}>
                  <Lottie options={lottieSetting(cloud)} />
                </div>
                <span>Run the Audit tool in 5 mins</span>
              </div>
              <div>
                <div className={classes.circle}>
                  <Lottie options={lottieSetting(secure)} />
                </div>
                <span>No client security access needed</span>
              </div>
              <div>
                <div className={classes.circle}>
                  <Lottie options={lottieSetting(bhagwan)} />
                </div>
                <span>Relax - As Lucidity analyzes the findings for you</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className={classes.values}>
              <div className={classes.heading}>
                <h2>
                  Find the <span>value</span> you seek
                </h2>
              </div>
              <div className={classes.leaders}>
                <span>For Business leaders</span>
                <span>
                  Control your disk costs better, understand where you are
                  spending more and optimize your disk spend like never before.
                </span>
              </div>
              <div>
                <div className={classes.leaders_points}>
                  <img src={Tick} alt="tick" />
                  <span>Find your potential savings</span>
                </div>
                <div className={classes.leaders_points}>
                  <img src={Tick} alt="tick" />
                  <span>Trace your wastage</span>
                </div>
              </div>
              <div className={classes.demobutton}>
                <DemoLargeButton link="/book-demo/audit" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
};

export default Deploy;
