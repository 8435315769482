import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie-segments";

import * as classes from "../../styles/audit/audit.module.scss";
// lottie files
import * as diskDowntime from "../../assets/images/audit/disk_downtime.json";
import * as diskSpend from "../../assets/images/audit/disk_spend.json";
import * as diskWaste from "../../assets/images/audit/disk_waste.json";

const lottieSetting = (data) => {
  return {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
};

const Analyze = () => {
  return (
    <section>
      <Container className={classes.audit__analyze}>
        <Row className="justify-content-center">
          <Col lg={8} xs={12} className="text-center p-0">
            <div className={classes.heading}>
              <h2>
                What does it <span>tell me?</span>
              </h2>
            </div>
          </Col>

          <div className={classes.disk}>
            <Row className="justify-content-center text-center">
              <Col lg={12}>
                <Row className={classes.disk__spend}>
                  <Col sm={6} className={classes.col_one}>
                    <Lottie options={lottieSetting(diskSpend)} />
                  </Col>
                  <Col sm={6} className={classes.col_two}>
                    <div className={classes.heading}>
                      <h2>
                        Overall <span>Disk Spend </span>
                      </h2>
                    </div>
                    <p>
                      Figure out your present disk spends, what should be the
                      optimized bill, and how you can reduce them by 70%
                    </p>
                  </Col>
                </Row>

                <Row className={classes.disk__waste}>
                  <Col sm={6} className={classes.col_one}>
                    <div className={classes.heading}>
                      <h2>
                        Disk <span>Wastage</span>
                      </h2>
                    </div>
                    <p>
                      Find the root causes of wastage like Idle Volumes &
                      Over-provisioning and learn how you can eliminate them
                    </p>
                  </Col>
                  <Col sm={6} className={classes.col_two}>
                    <Lottie options={lottieSetting(diskWaste)} />
                  </Col>
                </Row>

                <Row className={classes.disk__downtime}>
                  <Col sm={6} className={classes.col_one}>
                    <Lottie options={lottieSetting(diskDowntime)} />
                  </Col>
                  <Col sm={6} className={classes.col_two_last}>
                    <div className={classes.heading}>
                      <h2>
                        Disk <span>Downtime Risks</span>
                      </h2>
                    </div>
                    <p>
                      Identify potential downtimes before they happen, and avoid
                      financial and reputational losses
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Analyze;
