import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Tick from "../../assets/images/audit/tick.svg";
import DemoLargeButton from "../Common/DemoLargeButton";
import * as classes from "../../styles/audit/audit.module.scss";

const Hero = () => {
  return (
    <section className={classes.audit}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} xs={12} className="text-center p-0">
            <div
              className={`${classes["audit__heading"]} justify-content-center`}
            >
              <p>Wastage is meant for your bin,</p>
              <span>
                not your{" "}
                <span className={classes.cloud_primary}>cloud storage</span>
              </span>
            </div>
            <div className={classes.hero_body}>
              <span>
                Lucidity Storage Audit gives you complete storage visibility at
                the click of a button.
              </span>
              <span>
                &nbsp;Analyze how you spend, identify where you waste, and
                capture the risks of downtime.
              </span>
            </div>
            <div
              className={`${classes["audit__features"]} justify-content-center`}
            >
              <div>
                <img src={Tick} alt="tick" />
                <span>25 min Deployment time</span>
              </div>

              <div>
                <img src={Tick} alt="tick" />
                <span>No Access to Lucidity</span>
              </div>
              <div>
                <img src={Tick} alt="tick" />
                <span>Ohh and also It’s Free!</span>
              </div>
            </div>
            <div className={classes["audit__demobutton"]}>
              <DemoLargeButton link="/book-demo/audit" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
