import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import * as classes from "../../styles/audit/audit.module.scss";

const Results = ({title}) => {
  return (
    <section>
      <Container className={classes.audit__results}>
        <Row className="justify-content-center">
          <Col sm={8} lg={8} xs={8} className="text-center p-0">
            {title ? (
              <div className={classes.heading}>
              <h2>
              Lucidity Storage Audit Result <span>- Enterprises</span>
              </h2>
            </div>
            ) : (
              <div className={classes.heading}>
              <h2>
                And the <span>results?</span>
              </h2>
            </div>
            )}

          </Col>
        </Row>
        <Row className={`${classes.box_wrapper} justify-content-center`}>
          <Col lg={6} xs={12} className="text-center p-0">
            <div className={classes.box}>
              <div className={classes.circle}>
                <h4>60%</h4>
                <span>Cost Wasted</span>
              </div>
              <div className={classes.left_col}>
                <div className={classes.content}>
                  <span>
                    17% <span>Disk Utilization</span>
                  </span>
                </div>
                <div className={classes.content}>
                  <span>
                    1 <span>Downtime Avoided</span>
                  </span>
                </div>
              </div>
              <div className={classes.divider}></div>
              <div className={classes.right_col}>
                <h4>Food and Grocery retail</h4>
                <span>Fortune 500 Revenue <br></br>$9.348 Billion</span>
              </div>
            </div>
          </Col>
          <Col lg={6} xs={12} className="text-center p-0">
            <div className={classes.box}>
              <div className={classes.circle}>
                <h4>60%</h4>
                <span>Cost Wasted</span>
              </div>
              <div className={classes.left_col}>
                <div className={classes.content}>
                  <span>
                    18% <span>Disk Utilization</span>
                  </span>
                </div>
                <div className={classes.content}>
                  <span>
                    5 <span>Downtime Avoided</span>
                  </span>
                </div>
              </div>
              <div className={classes.divider}></div>
              <div className={classes.right_col}>
                <h4>Talent Management firm</h4>
                <span>Revenue $12.3 Billion</span>
              </div>
            </div>
          </Col>
          <Col lg={6} xs={12} className="text-center p-0">
            <div className={classes.box}>
              <div className={classes.circle}>
                <h4>52%</h4>
                <span>Cost Wasted</span>
              </div>
              <div className={classes.left_col}>
                <div className={classes.content}>
                  <span>
                    21% <span>Disk Utilization</span>
                  </span>
                </div>
                <div className={classes.content}>
                  <span>
                    2 <span>Downtime Avoided</span>
                  </span>
                </div>
              </div>
              <div className={classes.divider}></div>
              <div className={classes.right_col}>
                <h4>Consumer goods manufacturer</h4>
                <span>Fortune 500 Revenue $9.348 Billion</span>
              </div>
            </div>
          </Col>
          <Col lg={6} xs={12} className="text-center p-0">
            <div className={classes.box}>
              <div className={classes.circle}>
                <h4>45%</h4>
                <span>Cost Wasted</span>
              </div>
              <div className={classes.left_col}>
                <div className={classes.content}>
                  <span>
                    12% <span>Disk Utilization</span>
                  </span>
                </div>
                <div className={classes.content}>
                  <span>
                    3 <span>Downtime Avoided</span>
                  </span>
                </div>
              </div>
              <div className={classes.divider}></div>
              <div className={classes.right_col}>
                <h4>Insurance firm</h4>
                <span>Revenue $345 Million</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Results;
