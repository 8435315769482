import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import crossWhite from "../../assets/images/audit/cross_white.svg";
import votePrime from "../../assets/images/audit/vote_prime.svg";
import voteWhite from "../../assets/images/audit/vote_white.svg";
import Features from "./Features";
import * as classes from "../../styles/audit/audit.module.scss";

const StorageAudit = () => {
  const [slideFeatureNo, setSliderFeatureNo] = useState(1);

  const onHover = (e) => {
    let currId = Number(e.target.getAttribute("data-id"));
    if (currId !== 0) {
      setSliderFeatureNo(currId);
      let cList = document.getElementsByClassName(
        `${classes["features__container__sliderbackground"]}`
      )[0];
      setTimeout(() => {
        cList.classList.add(
          `${
            classes[`${"features__container__slidermargin" + String(currId)}`]
          }`
        );
        for (let i = 1; i < 6; i++) {
          if (i !== currId) {
            cList.classList.remove(
              `${classes[`${"features__container__slidermargin" + String(i)}`]}`
            );
          }
        }
      }, 70);
    }
  };

  return (
    <section className={classes.audit__storage}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={9} xs={12} className="text-center p-0">
            <div className={classes.heading}>
              <h2>
                Why Lucidity <span>Storage Audit?</span>
              </h2>
            </div>
            <div className={classes.body}>
              <p>
                What makes Lucidity Storage Audit the right choice for
                understanding your disk utilization? Manual discovery or
                utilizing monitoring tools are limited by tedious DevOps efforts
                or additional costs of deployment. With an ever-increasing
                complexity of storage environments, things can quickly spiral
                out of control.
              </p>
              <p>
                The Lucidity Storage Audit solves this problem by automating the
                entire process with the help of a simple ready-to-use executable
                tool. It helps understand your disk health and its utilization
                helping you optimize the spends and prevent downtime without any
                hassle.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={10}>
            <div className={`${classes["features__header"]}`}>
              <h3>&nbsp;</h3>
              <h3>Azure Monitor</h3>
              <h3>
                Monitoring tools <br></br>Eg. DataDog
              </h3>
              <h3>Lucidity</h3>
            </div>
            <div
              className={`${classes["features__container__sliderbackground"]} 
                        ${classes["features__container__slidermargin1"]}
                        ${
                          slideFeatureNo === 0
                            ? classes[
                                "features__container__slider_initial_margin"
                              ]
                            : ""
                        }
                      `}
            ></div>
            <Features
              dataTag={1}
              heading={"Monitoring Disk Spends"}
              azure={crossWhite}
              tools={crossWhite}
              lucidity={votePrime}
              imgSrc={crossWhite}
              active={slideFeatureNo === 1 ? "active" : ""}
              classes={
                slideFeatureNo === 1
                  ? `features__slider__opacity9`
                  : `features__slider__opacity${
                      slideFeatureNo >= 3 ? 1 : 8 - slideFeatureNo
                    }`
              }
              mouseEnter={onHover}
            />
            <Features
              dataTag={2}
              heading={"Report Disk Utilization"}
              azure={voteWhite}
              tools={voteWhite}
              lucidity={votePrime}
              imgSrc={crossWhite}
              active={slideFeatureNo === 2 ? "active" : ""}
              classes={
                slideFeatureNo === 2
                  ? `features__slider__opacity8`
                  : `features__slider__opacity${String(9 - slideFeatureNo)}`
              }
              mouseEnter={onHover}
            />
            <Features
              dataTag={3}
              heading={"Unearth wasted spend due to idle volumes"}
              azure={crossWhite}
              tools={voteWhite}
              lucidity={votePrime}
              active={slideFeatureNo === 3 ? "active" : ""}
              classes={
                slideFeatureNo === 3
                  ? `features__slider__opacity7`
                  : `features__slider__opacity${String(8 - slideFeatureNo)}`
              }
              mouseEnter={onHover}
            />
            <Features
              dataTag={4}
              heading={"Coverage across environments"}
              azureTxt={"100%"}
              toolsTxt={`50%`}
              toolsSubTxt={"(Typically Prod environment)"}
              lucidityTxt={`100%`}
              luciditySubTxt={"(Dev + Prod environments)"}
              active={slideFeatureNo === 4 ? "active" : ""}
              classes={
                slideFeatureNo === 4
                  ? `features__slider__opacity6`
                  : `features__slider__opacity${String(7 - slideFeatureNo)}`
              }
              mouseEnter={onHover}
            />
            <Features
              dataTag={5}
              heading={"Deployment Cost"}
              azureTxt={"Additional services costs"}
              toolsTxt={`Expensive`}
              lucidityTxt={`Free`}
              active={slideFeatureNo === 5 ? "active" : ""}
              classes={"features__slider__opacity55"}
              mouseEnter={onHover}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default StorageAudit;
