import React from "react";
import Analyze from "./Analyze";
import Deploy from "./Deploy";
import Faq from "./Faq";
import Hero from "./Hero";
import Results from "./Results";
import StorageAudit from "./StorageAudit";

const AuditComponent = () => {
  return (
   <>
      <Hero />
      <Analyze />
      <Results />
      <StorageAudit />
      <Deploy />
      <Faq />
   </>
  );
};

export default AuditComponent;
