import React from "react";

// local
import * as styles from "../../styles/audit/audit.module.scss";

const Features = ({
  dataTag,
  heading,
  azure,
  tools,
  lucidity,
  azureTxt,
  toolsTxt,
  toolsSubTxt,
  lucidityTxt,
  luciditySubTxt,
  active,
  classes,
  mouseEnter,
}) => {
  return (
    <>
      <div
        data-id={dataTag}
        className={`${styles["features__slider"]} ${
          active !== "active"
            ? `${styles[classes]}`
            : `${styles[`features__slider__${active}`]}`
        }`}
        onMouseEnter={mouseEnter}
      >
        <div
          data-id={dataTag}
          className={styles["features__slider__feature_name"]}
          onTouchStart={mouseEnter}
        >
          <span>{heading}</span>
        </div>

        <div className={styles["features__slider__blank"]}></div>

        <div className={styles["features__slider__azure"]}>
          {azureTxt ? <span>{azureTxt}</span> : <img src={azure} alt="cross" />}

          {dataTag === 2 && (
            <span className={styles.req}>Req. Multiple clicks</span>
          )}
        </div>

        <div className={styles["features__slider__tools"]}>
          {toolsTxt ? (
            <>
              <span>{toolsTxt}</span>
              {toolsSubTxt && <span>{toolsSubTxt}</span>}
            </>
          ) : (
            <img src={tools} alt={"cross"} />
          )}

          {dataTag === 2 && (
            <span className={styles.req}>Req. Multiple clicks</span>
          )}
        </div>

        <div className={styles["features__slider__lucidity"]}>
          {lucidityTxt ? (
            <>
              <span>{lucidityTxt}</span>
              {luciditySubTxt && <span>{luciditySubTxt}</span>}
            </>
          ) : (
            <img src={lucidity} alt={"lucidity"} />
          )}
          {dataTag === 2 && (
            <span className={styles.req}>Req. Only One click</span>
          )}
        </div>
      </div>
    </>
  );
};

export default Features;
