// extracted by mini-css-extract-plugin
export var audit = "audit-module--audit--tDMmm";
export var audit__analyze = "audit-module--audit__analyze--FbnFx";
export var audit__demobutton = "audit-module--audit__demobutton--oHN+I";
export var audit__deploy = "audit-module--audit__deploy--t1aBv";
export var audit__faq = "audit-module--audit__faq--hCvq8";
export var audit__features = "audit-module--audit__features--daxz8";
export var audit__heading = "audit-module--audit__heading--HSfrN";
export var audit__results = "audit-module--audit__results--qSkYU";
export var audit__storage = "audit-module--audit__storage--88s1q";
export var body = "audit-module--body--OKuCY";
export var box = "audit-module--box--aRsMg";
export var box_wrapper = "audit-module--box_wrapper--XJzSC";
export var circle = "audit-module--circle--0lEze";
export var cloud_primary = "audit-module--cloud_primary--LpKWs";
export var col_one = "audit-module--col_one--8w1jI";
export var col_two = "audit-module--col_two--Hjm0g";
export var col_two_last = "audit-module--col_two_last--utGRj";
export var content = "audit-module--content--PvKWr";
export var demobutton = "audit-module--demobutton--9xNcQ";
export var deploy_box = "audit-module--deploy_box--KGWhK";
export var disk = "audit-module--disk--Q5hwt";
export var disk__downtime = "audit-module--disk__downtime--T0bSd";
export var disk__spend = "audit-module--disk__spend--K9CQ1";
export var disk__waste = "audit-module--disk__waste--fLSZt";
export var divider = "audit-module--divider--eM3xM";
export var features = "audit-module--features--bhA+E";
export var features__container = "audit-module--features__container--XB-3r";
export var features__container__slider_initial_margin = "audit-module--features__container__slider_initial_margin--lG7TV";
export var features__container__sliderbackground = "audit-module--features__container__sliderbackground--ma49W";
export var features__container__slidermargin = "audit-module--features__container__slidermargin--dhSoq";
export var features__container__slidermargin1 = "audit-module--features__container__slidermargin1--KPwgz";
export var features__container__slidermargin2 = "audit-module--features__container__slidermargin2--9yoNt";
export var features__container__slidermargin3 = "audit-module--features__container__slidermargin3--8dncp";
export var features__container__slidermargin4 = "audit-module--features__container__slidermargin4--DIvZT";
export var features__container__slidermargin5 = "audit-module--features__container__slidermargin5--xtrMC";
export var features__header = "audit-module--features__header--ceRsH";
export var features__slider = "audit-module--features__slider--lPYbX";
export var features__slider__active = "audit-module--features__slider__active--akYvO";
export var features__slider__azure = "audit-module--features__slider__azure--pGtGS";
export var features__slider__blank = "audit-module--features__slider__blank--Vfupl";
export var features__slider__feature_name = "audit-module--features__slider__feature_name--s21ki";
export var features__slider__lucidity = "audit-module--features__slider__lucidity--PnAiN";
export var features__slider__tools = "audit-module--features__slider__tools--nhvRZ";
export var heading = "audit-module--heading--O4ore";
export var hero_body = "audit-module--hero_body--sVYFg";
export var leaders = "audit-module--leaders--WlD4N";
export var leaders_points = "audit-module--leaders_points---l0QA";
export var left_col = "audit-module--left_col--x4DqL";
export var req = "audit-module--req--fI7-u";
export var right_col = "audit-module--right_col--kBIEQ";
export var values = "audit-module--values--g7gIN";